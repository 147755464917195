

export const GrayBlockSchema = (intl) => ({
  title: 'Grid Items',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'gala_image', 'gala_url'],
    },
  ],

  properties: {
    text: {
      title: 'Grid Text',
      widget: 'textarea',
    },
    gala_image: {
      title: 'Teaser für Gala',
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
    },
    gala_url: {
      title: 'Url zur Gala-Seite',
    }
  },
  required: [],
});

