import React from 'react';
import loadable from '@loadable/component';
import '../style/style.css'
import { useIntl } from 'react-intl';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import View from './View';
import { GrayBlockSchema } from './schema';

const Edit = (props) => {
  const { block, data, selected } = props;

  const schema = GrayBlockSchema();

  const handleChange = (id, value) => {
    props.onChangeBlock(block, {
      ...data,
      [id]: value,
    });
  };

  return (
    <>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          formData={data}
          onChangeField={handleChange}
        />
      </SidebarPortal>
      <View {...props} isEditMode/>
    </>
  );
};
export default Edit;